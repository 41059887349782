import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import EnableTwoFactorModal from 'shared/components/TwoFactor/EnableTwoFactorModal';
import { disableTwoFactor } from 'shared/redux/modules/session';
import { twoFactorEnabled } from 'app/modules/profilePage';
import ChangeRequestCard from '../shared/ChangeRequestCard';
import Button from 'shared/components/tailwind/Button';

const ns = 'app.profilePage.twoFactorSettings';

class TwoFactorSettings extends Component {
  static propTypes = {
    enabled: PropTypes.bool.isRequired,
    phone: PropTypes.string,
    disableTwoFactor: PropTypes.func.isRequired,
    twoFactorEnabled: PropTypes.func.isRequired,
  };

  state = {
    showEnableModal: false,
    showDisableModal: false,
  };

  handleShowEnableModal = () => {
    this.setState({ showEnableModal: true });
  };

  handleHideEnableModal = () => {
    this.setState({ showEnableModal: false });
  };

  handleShowDisableModal = () => {
    this.setState({ showDisableModal: true });
  };

  handleHideDisableModal = () => {
    this.setState({ showDisableModal: false });
  };

  handleDisable = () => {
    this.props.disableTwoFactor();
    this.handleHideDisableModal();
  };

  renderEnabled() {
    const { phone } = this.props;
    const { showDisableModal } = this.state;

    return (
      <>
        <ChangeRequestCard
          title={I18n.nt(ns, 'title')}
          description={I18n.nt(
            ns,
            'alreadyEnabled.description',
            {},
            { phone_number: phone }
          )}
          buttonText={I18n.nt(ns, 'alreadyEnabled.deactivate')}
          onButtonClick={this.handleShowDisableModal}
        />
        <Modal show={showDisableModal} onHide={this.handleHideDisableModal}>
          <Modal.Header closeButton>
            <Modal.Title>
              {I18n.nt(ns, 'alreadyEnabled.confirm_title')}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {I18n.nt(ns, 'alreadyEnabled.confirm_description')}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={this.handleHideDisableModal}>
              {I18n.nt(ns, 'alreadyEnabled.cancel')}
            </Button>
            <Button className="btn btn-danger" onClick={this.handleDisable}>
              {I18n.nt(ns, 'alreadyEnabled.confirm')}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }

  renderNotEnabled() {
    const { showEnableModal } = this.state;

    return (
      <>
        <ChangeRequestCard
          title={I18n.nt(ns, 'title')}
          description={I18n.nt(ns, 'description')}
          buttonText={I18n.nt(ns, 'enableButton')}
          onButtonClick={this.handleShowEnableModal}
          colSize={6}
        />
        <EnableTwoFactorModal
          hideModal={this.handleHideEnableModal}
          show={showEnableModal}
          twoFactorEnabled={this.props.twoFactorEnabled}
        />
      </>
    );
  }

  render() {
    if (this.props.enabled) {
      return this.renderEnabled();
    } else {
      return this.renderNotEnabled();
    }
  }
}

export default connect(null, {
  disableTwoFactor,
  twoFactorEnabled,
})(TwoFactorSettings);
