import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';
import { cleanIban } from 'models/Iban';

import resolver from 'hookForm/resolver';
import { validateIban } from 'shared/utils/validation';
import client from 'shared/utils/client';
import { Seller } from 'types';
import { useCurrentSeller } from 'namespaces/shared/hooks';

interface FormValues {
  explanation?: string;
  newIban: string;
}

type Status = '' | 'error' | 'requesting' | 'requested';

const useChangeIbanForm = () => {
  const [status, setStatus] = useState<Status>('');

  const validate = ({ newIban }: FormValues) => {
    const cleanedIban = cleanIban(newIban || '');

    return {
      newIban: validateIban(cleanedIban),
    };
  };

  const currentSeller = useCurrentSeller() as Seller;

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const requestIbanChange = async (data: FormValues) => {
    setStatus('requesting');

    const response = await client(
      'POST',
      '/api/request_changes/iban',
      {
        newIban: cleanIban(data.newIban),
        explanation: data.explanation,
      },
      {
        raiseError: false,
      }
    );

    if (response.error) {
      setStatus('error');
    } else {
      setStatus('requested');
      reset();
    }
  };

  const resetForm = useCallback(() => {
    setStatus('');
    reset();
  }, []);

  return {
    control,
    handleSubmit: handleSubmit(requestIbanChange),
    isSubmitting,
    resetForm,
    currentSeller,
    status,
  };
};

export default useChangeIbanForm;
