import React from 'react';
import { Alert, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import TextInput from 'hookForm/TextInput';
import useChangeFlorynInvoiceCcEmail from './useChangeFlorynInvoiceCcEmail';
import ChangeRequestCard from '../shared/ChangeRequestCard';
import Button from 'shared/components/tailwind/Button';

const ns = 'app.profilePage.changeFlorynInvoiceCcEmail';

const ChangeFlorynInvoiceCcEmail = () => {
  const {
    control,
    emailOfMainUser,
    isSubmitting,
    isSubmitSuccessful,
    isValid,
    submit,
  } = useChangeFlorynInvoiceCcEmail();

  const [showModal, setShowModal] = React.useState(false);

  const handleClose = () => setShowModal(false);
  const handleShow = () => setShowModal(true);

  return (
    <>
      <ChangeRequestCard
        title={I18n.nt(ns, 'title')}
        description={I18n.nt(ns, 'intro', { email: emailOfMainUser })}
        buttonText={I18n.nt(ns, 'button')}
        onButtonClick={handleShow}
        disabled={isSubmitting}
        buttonTestId="CcEmail"
      />
      <Modal
        show={showModal}
        onHide={handleClose}
        data-testid="changeFlorynInvoiceCcEmailModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>{I18n.nt(ns, 'title')}</Modal.Title>
        </Modal.Header>
        <form autoComplete="off" onSubmit={submit}>
          <Modal.Body>
            <p>{I18n.nt(ns, 'intro', { email: emailOfMainUser })}</p>
            <TextInput
              control={control}
              label={I18n.nt(ns, 'label')}
              name="florynInvoiceCcEmail"
              type="email"
            />
            {isSubmitSuccessful && (
              <Alert type="success" className="tw-mt-4">
                {I18n.nt(ns, 'submitted')}
              </Alert>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={handleClose}>{I18n.nt(ns, 'cancel')}</Button>
            <Button
              className="btn-primary"
              type="submit"
              disabled={isSubmitting || !isValid}
            >
              {I18n.nt(ns, 'submit')}
            </Button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default ChangeFlorynInvoiceCcEmail;
