import React from 'react';
import ChangeAddressModal from './ChangeAddressModal';
import ChangeRequestCard from '../shared/ChangeRequestCard';
import useServicePage from '../ServicePage/useServicePage';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.profilePage.changeAddress';

const ChangeAddressRequest = () => {
  const { action, setAction, resetAction } = useServicePage();

  return (
    <>
      <ChangeRequestCard
        title={I18n.nt(ns, 'title')}
        description={I18n.nt(ns, 'intro')}
        buttonText={I18n.nt(ns, 'button')}
        onButtonClick={() => setAction('changeAddress')}
        disabled={action !== ''}
      />
      <ChangeAddressModal
        onHide={resetAction}
        show={action === 'changeAddress'}
      />
    </>
  );
};

export default ChangeAddressRequest;
