import React, { ChangeEvent, useState } from 'react';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import { nt } from 'shared/utils/I18n';
import InputWrapper from './InputWrapper';
import ErrorMessage from './ErrorMessage';
import useBaseCurrencyInput, {
  convertToFormValue,
} from './elements/useBaseCurrencyInput';

const ns = 'form.currencyInputWithSlider';

interface Props<TFieldValues extends FieldValues> {
  autoFocus?: boolean;
  className?: string;
  control: Control<TFieldValues>;
  label: string;
  name: Path<TFieldValues>;
  placeholder?: string;
  required?: boolean;
  steps: number[];
}

const CurrencyInputWithSlider = <TFieldValues extends FieldValues>({
  autoFocus,
  className,
  control,
  label,
  name,
  placeholder,
  required,
  steps,
}: Props<TFieldValues>) => {
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error, invalid },
  } = useController<TFieldValues>({ control, name });

  const numberToSliderValue = (inputValue: number) => {
    return steps.reduce((closestIndex, stepValue, index) => {
      const closestDelta = Math.abs(steps[closestIndex] - inputValue);
      const delta = Math.abs(stepValue - inputValue);

      if (delta < closestDelta) {
        return index;
      } else {
        return closestIndex;
      }
    }, 0);
  };

  const [id] = useState(generateHtmlElementId);
  const [sliderId] = useState(generateHtmlElementId);
  const [errorMessageId] = useState(generateHtmlElementId);
  const [hasFocus, setHasFocus] = useState(false);
  const [sliderValue, setSliderValue] = useState(() =>
    numberToSliderValue(parseInt(value))
  );

  const onBlurInput = () => {
    setHasFocus(false);
    onBlur();
  };

  const onFocusInput = () => {
    setHasFocus(true);
  };

  const {
    displayValue,
    handleBeforeInput,
    handleBlur,
    handleChange: handleCurrencyInputChange,
    handleFocus,
    setValue: setCurrentInputValue,
  } = useBaseCurrencyInput({
    formatOptions: { displayCurrenySymbol: true, numberOfDecimals: 0 },
    onBlur: onBlurInput,
    onChange,
    onFocus: onFocusInput,
    value,
  });

  const sliderPercentage = (sliderValue * 100) / (steps.length - 1);

  const handleSliderChange = (e) => {
    const valueFromSlider = steps[e.target.value] || 0;
    setCurrentInputValue(`${valueFromSlider}`);
    setSliderValue(e.target.value);
  };

  const handleTextInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSliderValue(
      numberToSliderValue(convertToFormValue(e.target.value, 0) || 0)
    );
    handleCurrencyInputChange(e);
  };

  return (
    <InputWrapper
      className="CurrencyInputWithSlider"
      id={id}
      invalid={Boolean(error) && !hasFocus}
      label={label}
      name={name}
      required={required}
      type="currency"
    >
      <input
        aria-errormessage={invalid ? errorMessageId : undefined}
        aria-invalid={invalid ? true : undefined}
        autoFocus={autoFocus}
        className={className ? `form-control ${className}` : 'form-control'}
        id={id}
        inputMode="decimal"
        name={name}
        onBeforeInputCapture={handleBeforeInput}
        onBlur={handleBlur}
        onChange={handleTextInputChange}
        onFocus={handleFocus}
        placeholder={placeholder}
        ref={ref}
        type="text"
        value={displayValue}
      />
      <label className="sr-only" htmlFor={sliderId}>
        {label} {nt(ns, 'slider')}
      </label>
      <input
        type="range"
        id={sliderId}
        max={steps.length - 1}
        step={1}
        value={sliderValue}
        onChange={handleSliderChange}
        style={{ backgroundSize: `${sliderPercentage}% 100%` }}
      />
      <ErrorMessage error={error} id={errorMessageId} />
    </InputWrapper>
  );
};

export default CurrencyInputWithSlider;
