import {
  addDays,
  format,
  formatDistanceToNow,
  isBefore,
  isThisYear,
  parseISO,
} from 'date-fns';
import { nl } from 'date-fns/locale';
import { Consent, Account, Bank } from 'types';
import * as I18n from 'shared/utils/I18n';
import { chain } from 'lodash';

/**
 * Specialised translation function specifically for the RefreshConsentsModal
 * module, fixing the translation namespace.
 */
export function t(
  key: string,
  interpolatedValues: Record<string, unknown> = {}
) {
  return I18n.nt('app.profile.refreshModal', key, interpolatedValues);
}

/**
 * Check if a single date/time is within the next 14 days.
 *
 * Side effects: this function will read the current time.
 */
export function almostExpiring(date: Date) {
  return isBefore(date, addDays(new Date(), 14));
}

/**
 * Check if an account can be refreshed already — which is allowed
 * when it expires within the next 60 days.
 *
 * Side effects: this function will read the current time.
 */
export function allowAccountRefresh(account: Account): boolean {
  switch (account.type) {
    case 'active':
      return isBefore(account.expiresAt, addDays(new Date(), 60));
    default:
      return true;
  }
}

/**
 * Check if a bank has any account that can be refreshed already.
 *
 * See `allowAccountRefresh`.
 */
export function allowBankRefresh(bank: Bank): boolean {
  return bank.accounts.some(allowAccountRefresh);
}

/**
 * Check if any bank account has any account that can be refreshed already.
 *
 * See `allowBankRefresh`.
 */
export function allowAnyRefresh(banks: Bank[]): boolean {
  return banks.some(allowBankRefresh);
}

/**
 * Check whether we think an account needs to be refreshed soon-ish. We use
 * this to determine whether to show the consent refresh modal dialog when the
 * seller logs in.
 *
 * See `almostExpiring`.
 */
function requireAccountRefresh(account: Account): boolean {
  switch (account.type) {
    case 'active':
      return almostExpiring(account.expiresAt);
    default:
      return true;
  }
}

/**
 * Check if a bank has accounts that need their consents to be refreshed.
 *
 * See `requireAccountRefresh`.
 */
function requireBankRefresh(bank: Bank): boolean {
  return bank.accounts.some((account) => requireAccountRefresh(account));
}

/**
 * Check if any bank account will be needed a consent refresh soon
 * enough to warrant displaying the refresh modal dialog.
 *
 * See `requireBankRefresh`.
 */
export function requireAnyRefresh(banks: Bank[]): boolean {
  return banks.some((bank) => requireBankRefresh(bank));
}

/**
 * Get a human-friendly formatting of a date.
 *
 * - use the day and month.
 * - when the future date is in the next year, add the year.
 * - when the future date is in the next 14 days, add a
 *   relative number of days.
 *
 * Side effects: this function will read the current time.
 *
 */
export function describeTimeUntilDate(date: Date): [string, string | null] {
  if (almostExpiring(date)) {
    return [
      format(date, 'd MMMM', { locale: nl }),
      formatDistanceToNow(date, { locale: nl }),
    ];
  } else {
    if (isThisYear(date)) {
      return [format(date, 'd MMMM', { locale: nl }), null];
    } else {
      return [format(date, 'd MMMM yyyy', { locale: nl }), null];
    }
  }
}

/**
 * Check if the given date has already been passed
 */
export function isExpired(expirationDate: Date): boolean {
  return isBefore(expirationDate, new Date());
}

/**
 * Take values from the remote AIS API giving us `Consent`s and transform these
 * into `Bank` values.
 */
export function transformAccountsIntoBanks(consents: Consent[]): Bank[] {
  return chain(consents)
    .filter('iban')
    .map((consent: Consent): Account => {
      if (consent.expiresOn) {
        return {
          bank: consent.bank,
          iban: consent.iban,
          type: 'active',
          expiresAt: parseISO(consent.expiresOn),
        };
      } else {
        return { bank: consent.bank, iban: consent.iban, type: 'expired' };
      }
    })
    .groupBy('bank')
    .map((consents, name) => {
      return { name, accounts: consents };
    })
    .value();
}
