import React from 'react';
import Button from 'shared/components/tailwind/Button';

interface Props {
  title: string;
  description: string;
  buttonText: string;
  onButtonClick: () => void;
  disabled?: boolean;
  buttonTestId?: string;
}

const ChangeRequestCard = ({
  title,
  description,
  buttonText,
  onButtonClick,
  disabled = false,
  buttonTestId,
}: Props) => {
  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-rounded-lg tw-bg-floryn-tint-gray tw-p-6">
      <p className="tw-mb-3 tw-font-heading tw-font-semibold">{title}</p>
      <p className="tw-mb-6 tw-flex-1">{description}</p>
      <Button
        className="btn-primary tw-w-fit"
        onClick={onButtonClick}
        disabled={disabled}
        data-testid={buttonTestId ? `${buttonTestId}Button` : undefined}
      >
        {buttonText}
      </Button>
    </div>
  );
};

export default ChangeRequestCard;
