import React from 'react';
import classNames from 'classnames';
import { Control, FieldValues, Path } from 'react-hook-form';
import { truncate } from 'lodash';
import { nt } from 'shared/utils/I18n';
import InputWrapper from 'hookForm/InputWrapper';
import useAttachmentsInput, { TransferStatus } from './useAttachmentsInput';
import { Attachment } from 'types';
import LoadingAnimation from 'shared/components/LoadingAnimation';

const ns = 'form.attachmentsInput';

const transferStatusToText = (transferStatus: TransferStatus) => {
  switch (transferStatus) {
    case 'done':
      return nt(ns, 'done');
    case 'uploading':
      return nt(ns, 'uploading');
    case 'notStarted':
    default:
      return '';
  }
};

interface Props<TFieldValues extends FieldValues> {
  allowMultipleFiles?: boolean;
  control: Control<TFieldValues>;
  description?: string;
  hideLabel?: boolean;
  includeLayoutClass?: boolean;
  label: string;
  model: string;
  modelId?: number;
  name: Path<TFieldValues>;
  readOnly?: boolean;
  renderDocumentMessage?: (attachment: Attachment) => React.ReactNode;
  required?: boolean;
  setUploading?: (uploading: boolean) => void;
}

const AttachmentsInput = <TFieldValues extends FieldValues>(
  props: Props<TFieldValues>
) => {
  const {
    allowMultipleFiles = true,
    control,
    description,
    hideLabel,
    includeLayoutClass,
    label,
    model,
    modelId,
    name,
    readOnly,
    renderDocumentMessage,
    required,
    setUploading,
  } = props;

  const {
    attachments,
    dropping,
    errors,
    handleDragEnter,
    handleDragLeave,
    handleDragOver,
    handleDrop,
    handleInputChange,
    id,
    inputRef,
    removeAttachment,
    transfers,
  } = useAttachmentsInput({
    allowMultipleFiles,
    control,
    model,
    modelId,
    name,
    readOnly,
    setUploading,
  });

  const inputReadonly = readOnly || transfers.length > 0;

  return (
    <InputWrapper
      className="AttachmentsInput style-charlie"
      id={id}
      hideLabel={hideLabel}
      includeLayoutClass={includeLayoutClass}
      label={label}
      name={name}
      required={required}
      type="documents"
    >
      {description && <div className="tw-mb-3">{description}</div>}
      <div
        className={classNames('droparea', { dropping })}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
      >
        {(attachments.length > 0 ||
          transfers.length > 0 ||
          errors.length > 0) && (
          <ul className="documents position-top">
            {attachments.map((attachment) => (
              <li
                key={`attachment-${attachment.id}`}
                className="document-input-list-item"
              >
                <span className="document-input-list-item__file">
                  <a
                    rel="noopener noreferrer"
                    className="document-input-list-item__link"
                    target="_blank"
                    href={`/api/attachments/${attachment.id}/download`}
                  >
                    {truncate(attachment.fileName, { length: 60 })}
                  </a>
                  <span
                    className="document-input-list-item__remove"
                    onClick={() => removeAttachment(attachment.id)}
                  >
                    <span className="document-input-list-item__remove__icon">
                      <span className="sr-only">{nt(ns, 'delete')}</span>
                    </span>
                  </span>
                </span>
                {renderDocumentMessage && renderDocumentMessage(attachment)}
              </li>
            ))}
            {transfers.map((transfer) => (
              <li
                key={`upload-${transfer.uuid}`}
                className="document-input-list-item"
              >
                <span className="document-input-list-item__file">
                  <span className="document-input-list-item__file-name">
                    {transfer.fileName}
                  </span>
                  <span className="document-input-list-item__upload">
                    <span className="document-input-list-item__upload__text">
                      {transferStatusToText(transfer.status)}
                    </span>
                    {transfer.status === 'uploading' && (
                      <LoadingAnimation className="inline" />
                    )}
                  </span>
                </span>
              </li>
            ))}
            {errors.map((error, i) => (
              <li key={`error_${i}`} className="uploadError">
                {error}
              </li>
            ))}
          </ul>
        )}
        <div className="uploadContainer tw-flex tw-flex-col tw-items-center">
          <div className="tw-hidden tw-p-2 md:tw-block">
            <div className="tw-mb-0 tw-text-center tw-font-heading tw-text-lg tw-font-semibold tw-text-gray-600">
              {nt(
                ns,
                allowMultipleFiles ? 'drop_info_plural' : 'drop_info_singular'
              )}
            </div>
            <div className="tw-flex tw-flex-row">
              <hr className="tw-border-1 tw-w-36 tw-grow tw-border-solid tw-border-gray-400 tw-align-middle" />
              <div className="tw-my-auto tw-px-2 tw-text-lg tw-font-normal tw-text-gray-300">
                {nt(ns, 'or')}
              </div>
              <hr className="tw-border-1 tw-w-36 tw-grow tw-border-solid tw-border-gray-400 tw-align-middle" />
            </div>
          </div>
          <div className="upload md:tw-pb-2">
            <label
              className={classNames('btn btn-primary-inverted', {
                'btn-disabled': inputReadonly,
              })}
              htmlFor={id}
              data-readonly={inputReadonly || undefined}
            >
              {nt(
                ns,
                allowMultipleFiles
                  ? 'select_file_plural'
                  : 'select_file_singular'
              )}
            </label>
            <input
              id={id}
              multiple={allowMultipleFiles}
              onChange={handleInputChange}
              ref={inputRef}
              type="file"
              disabled={inputReadonly}
            />
          </div>
        </div>
      </div>
    </InputWrapper>
  );
};

export default AttachmentsInput;
