import React from 'react';
import ChangeEmailModal from './ChangeEmailModal';
import ChangeRequestCard from '../shared/ChangeRequestCard';
import useServicePage from '../ServicePage/useServicePage';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.profilePage.changeEmail';

const ChangeEmailRequest = () => {
  const { action, setAction, resetAction } = useServicePage();

  return (
    <>
      <ChangeRequestCard
        title={I18n.nt(ns, 'title')}
        description={I18n.nt(ns, 'intro')}
        buttonText={I18n.nt(ns, 'button')}
        onButtonClick={() => setAction('changeEmail')}
        disabled={action !== ''}
      />
      <ChangeEmailModal onHide={resetAction} show={action === 'changeEmail'} />
    </>
  );
};

export default ChangeEmailRequest;
