import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import useChangeAddressForm from './useChangeAddressForm';
import ServerError from 'namespaces/shared/components/ServerError';
import LoadingAnimation from 'namespaces/shared/components/LoadingAnimation';
import TextInput from 'hookForm/TextInput';
import TextArea from 'hookForm/TextArea';

interface Props {
  onHide: () => void;
  show: boolean;
}

const ns = 'app.profilePage.changeAddress.modal';

const ChangeAddressModal = ({ onHide, show }: Props) => {
  const { company, status, control, isSubmitting, handleSubmit, resetForm } =
    useChangeAddressForm();

  const handleClose = () => {
    onHide();
    setTimeout(() => {
      resetForm();
    }, 300);
  };

  if (!company) {
    return null;
  }

  const { houseNumber, street, postalCode, city } = company;

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'requestAddressChange')}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit} className="ChangeAddressForm">
        <Modal.Body>
          {status === 'error' && <ServerError />}
          {status === 'requesting' && <LoadingAnimation />}
          {status === 'requested' ? (
            <div>{I18n.nt(ns, 'successMessage')}</div>
          ) : (
            <>
              <div className="tw-mb-4">
                <label className="control-label">
                  {I18n.nt(ns, 'currentAddress')}
                </label>
                <input
                  type="text"
                  className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-p-2 tw-shadow-sm"
                  disabled
                  value={`${street} ${houseNumber}, ${postalCode} ${city}`}
                />
              </div>
              <div className="tw-flex tw-gap-8">
                <div className="tw-w-3/4">
                  <TextInput
                    data-autofocus
                    control={control}
                    label={I18n.nt(ns, 'newStreet')}
                    name="newStreet"
                  />
                </div>
                <div className="tw-w-1/4">
                  <TextInput
                    control={control}
                    label={I18n.nt(ns, 'newHouseNumber')}
                    name="newHouseNumber"
                  />
                </div>
              </div>
              <div className="tw-flex tw-gap-8">
                <div className="tw-w-full">
                  <TextInput
                    control={control}
                    label={I18n.nt(ns, 'newPostalCode')}
                    name="newPostalCode"
                  />
                </div>
                <div className="tw-w-full">
                  <TextInput
                    control={control}
                    label={I18n.nt(ns, 'newCity')}
                    name="newCity"
                  />
                </div>
              </div>
              <TextArea
                autoFocus
                control={control}
                label={I18n.nt(ns, 'explanation')}
                name="explanation"
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {status === 'requested' ? (
            <Button className="btn-primary" onClick={handleClose}>
              {I18n.nt(ns, 'goBack')}
            </Button>
          ) : (
            <>
              <Button onClick={handleClose}>{I18n.nt(ns, 'cancel')}</Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                className="btn-primary"
              >
                {I18n.nt(ns, 'submit')}
              </Button>
            </>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangeAddressModal;
