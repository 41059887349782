import { usesClientBankAccount } from 'charlie/model';
import { BaseSeller, Contract, Seller } from 'types';

export const isAlfa = (seller?: BaseSeller) =>
  (seller && seller.productVersion === 'alfa') || false;

export const isCharlie = (seller?: BaseSeller) =>
  (seller && seller.productVersion === 'charlie') || false;

export const isCharlieWithCreditProduct = (seller?: BaseSeller) =>
  (seller && seller.productVersion === 'charlie' && !seller.hasLoan) || false;

export const isDelta = (seller?: BaseSeller) =>
  (seller && seller.productVersion === 'delta') || false;

export const isDraft = (seller?: BaseSeller) =>
  (seller && seller.status === 'draft') || false;

export const isSubmitted = (seller?: BaseSeller) =>
  (seller && seller.status === 'submitted') || false;

export const isApproved = (seller?: BaseSeller) =>
  (seller && seller.status === 'approved') || false;

export const isCancelled = (seller?: BaseSeller) =>
  (seller && seller.status === 'cancelled') || false;

export const isActive = (seller) =>
  seller &&
  seller.status !== 'lost' &&
  seller.status !== 'inactive' &&
  seller.status !== 'rejected';

export const hasSubmittedStatements = (seller?: Seller) =>
  seller && (hasPsd2Connection(seller) || hasBankStatementAttachments(seller));

export const hasBankStatementAttachments = (seller: Seller) =>
  seller.bankStatementAttachments && seller.bankStatementAttachments.length > 0;

export const identificationRejected = ({ identificationState }) =>
  identificationState === 'rejected';

export const hasDeterminedLimitsAndRates = ({
  closingCommissionRate,
  creditLimit,
  interestRate,
  serviceFeeRate,
  withdrawalFeeRate,
}: Seller) => {
  if (creditLimit === null || creditLimit === undefined) {
    return false;
  }

  if (interestRate === null || interestRate === undefined) {
    return false;
  }

  // True if: the seller has a withdrawal fee, a closing commission, or a service fee (or a combination of them)
  return (
    parseFloat(withdrawalFeeRate) !== 0 ||
    parseFloat(closingCommissionRate) !== 0 ||
    parseFloat(serviceFeeRate) !== 0
  );
};

export const bankAccountVerified = (seller: Seller) =>
  seller.bankAccountVerificationStatus === 'verified';

export const pendingCentVerification = (seller: Seller) =>
  seller.bankAccountVerificationStatus === 'pending_cent_verification';

export const isSellerSignup = (seller: Seller): boolean =>
  seller.signupBy !== 'partner' && seller.signupBy !== 'floryn';

export const improvedCustomerContractsEnabled = (
  seller: BaseSeller
): boolean => {
  return seller.aBTests['improved_customer_contracts'] === true;
};

export const deliveryRequestStatementValidationEnabled = (
  seller: BaseSeller
): boolean => {
  return seller.aBTests['delivery_request_statement_validation'] === true;
};

export interface FinanceConditions {
  availableBalance: number;
  closingCommissionAmount: number;
  creditLimit: number;
  currentBalance: number;
  debtCollectionRate: number;
  interestRate: number;
  minimumWithdrawalAmount: number;
  serviceFeeRate: number;
  usesClientBankAccount: boolean;
  withdrawalFeeRate: number;
  withdrawalWillRequireApproval: boolean;
}

export const financeConditions = (seller: Seller): FinanceConditions => {
  const availableBalance = parseFloat(seller.availableBalance);
  const closingCommissionAmount = parseFloat(seller.closingCommissionAmount);
  const creditLimit = parseFloat(seller.creditLimit || '0');
  const currentBalance = creditLimit - availableBalance;
  const debtCollectionRate = parseFloat(seller.debtCollectionRate);
  const interestRate = parseFloat(seller.interestRate || '0');
  const minimumWithdrawalAmount = parseFloat(seller.minimumWithdrawalAmount);
  const serviceFeeRate = parseFloat(seller.serviceFeeRate);
  const withdrawalFeeRate = parseFloat(seller.withdrawalFeeRate);
  const withdrawalWillRequireApproval = seller.withdrawalWillRequireApproval;

  return {
    availableBalance,
    closingCommissionAmount,
    creditLimit,
    currentBalance,
    debtCollectionRate,
    interestRate,
    minimumWithdrawalAmount,
    serviceFeeRate,
    usesClientBankAccount: usesClientBankAccount(seller),
    withdrawalFeeRate,
    withdrawalWillRequireApproval,
  };
};

export const isAisConnected = (seller?: BaseSeller) => {
  return seller && seller.aisConnected;
};

export const hasPsd2Connection = (seller: Seller) => {
  return isAisConnected(seller);
};

export const hasSignedContract = (contract: Contract): boolean =>
  contract?.contractType !== 'manual' && contract?.state === 'signed';

export const hasSignedGuarantee = (contract: Contract): boolean =>
  Boolean(contract.guarantee?.guaranteeSignedAt);

type Product = 'credit' | 'factoring' | 'loan' | 'mortgage';

export const getProduct = (seller: BaseSeller): Product => {
  if (seller.productVersion === 'delta') {
    return 'mortgage';
  } else if (seller.productVersion === 'alfa') {
    return 'factoring';
  } else if (seller.hasLoan) {
    return 'loan';
  } else {
    return 'credit';
  }
};
