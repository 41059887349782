import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import useChangePhoneForm from './useChangePhoneForm';
import ServerError from 'namespaces/shared/components/ServerError';
import LoadingAnimation from 'namespaces/shared/components/LoadingAnimation';
import TextArea from 'hookForm/TextArea';
import TextInput from 'hookForm/TextInput';

interface Props {
  onHide: () => void;
  show: boolean;
}

const ns = 'app.profilePage.changePhone.modal';

const ChangePhoneModal = ({ onHide, show }: Props) => {
  const {
    status,
    control,
    isSubmitting,
    handleSubmit,
    resetForm,
    currentSeller,
  } = useChangePhoneForm();

  const handleClose = () => {
    onHide();
    setTimeout(() => {
      resetForm();
    }, 300);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'requestPhoneChange')}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit} className="ChangePhoneForm">
        <Modal.Body>
          {status === 'error' && <ServerError />}
          {status === 'requesting' && <LoadingAnimation />}
          {status === 'requested' ? (
            <div>{I18n.nt(ns, 'successMessage')}</div>
          ) : (
            <>
              {currentSeller.phone && (
                <div className="tw-mb-4">
                  <label className="control-label">
                    {I18n.nt(ns, 'currentPhone')}
                  </label>
                  <input
                    type="text"
                    className="tw-mt-1 tw-block tw-w-full tw-rounded-md tw-border tw-p-2 tw-shadow-sm"
                    disabled
                    value={currentSeller.phone}
                    data-testid="currentPhone"
                  />
                </div>
              )}
              <div className="tw-flex tw-gap-8">
                <div className="tw-w-full">
                  <TextInput
                    data-autofocus
                    placeholder="+31612345678"
                    control={control}
                    label={I18n.nt(ns, 'newPhone')}
                    name="newPhone"
                    type="tel"
                  />
                </div>
              </div>
              <TextArea
                control={control}
                label={I18n.nt(ns, 'explanation')}
                name="explanation"
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {status === 'requested' ? (
            <Button className="btn-primary" onClick={handleClose}>
              {I18n.nt(ns, 'goBack')}
            </Button>
          ) : (
            <>
              <Button onClick={handleClose}>{I18n.nt(ns, 'cancel')}</Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                className="btn-primary"
              >
                {I18n.nt(ns, 'submit')}
              </Button>
            </>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangePhoneModal;
