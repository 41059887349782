import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import resolver from 'hookForm/resolver';
import {
  validateEmail,
  validateConfirmation,
  validatePresence,
} from 'shared/utils/validation';
import client from 'shared/utils/client';

interface FormValues {
  newEmail: string;
  newEmailConfirmation: string;
}

type Status = '' | 'error' | 'requesting' | 'requested';

const useChangeEmailRequest = () => {
  const validate = ({ newEmail, newEmailConfirmation }: FormValues) => ({
    newEmail: validateEmail(newEmail),
    newEmailConfirmation:
      validatePresence(newEmailConfirmation) ||
      validateConfirmation(newEmail, newEmailConfirmation),
  });

  const {
    control,
    getValues,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const [status, setStatus] = useState<Status>('');

  const submit = async ({ newEmail }: FormValues) => {
    setStatus('requesting');

    const response = await client(
      'POST',
      '/api/user_change_emails',
      { newEmail },
      { raiseError: false }
    );

    if (response.error) {
      setStatus('error');
    } else {
      setStatus('requested');
    }
  };

  const { newEmail } = getValues();

  const resetForm = useCallback(() => {
    setStatus('');
    reset();
  }, []);

  return {
    control,
    handleSubmit: handleSubmit(submit),
    isSubmitting,
    newEmail,
    resetForm,
    status,
  };
};

export default useChangeEmailRequest;
