import React from 'react';
import ChangePhoneModal from './ChangePhoneModal';
import ChangeRequestCard from '../shared/ChangeRequestCard';
import useServicePage from '../ServicePage/useServicePage';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.profilePage.changePhone';

const ChangePhoneRequest = () => {
  const { action, setAction, resetAction } = useServicePage();

  return (
    <>
      <ChangeRequestCard
        title={I18n.nt(ns, 'title')}
        description={I18n.nt(ns, 'intro')}
        buttonText={I18n.nt(ns, 'button')}
        onButtonClick={() => setAction('changePhone')}
        disabled={action !== ''}
      />
      <ChangePhoneModal onHide={resetAction} show={action === 'changePhone'} />
    </>
  );
};

export default ChangePhoneRequest;
