import React, { InputHTMLAttributes, useState } from 'react';
import { isUndefined } from 'lodash';
import { Control, FieldValues, Path, useController } from 'react-hook-form';
import { generateHtmlElementId } from 'shared/utils/Helpers';
import ErrorMessage from './ErrorMessage';
import InputWrapper from './InputWrapper';

interface Props<TFieldValues extends FieldValues>
  extends InputHTMLAttributes<HTMLTextAreaElement> {
  autoFocus?: boolean;
  control: Control<TFieldValues>;
  hideLabel?: boolean;
  includeLayoutClass?: boolean;
  label: string;
  name: Path<TFieldValues>;
  placeholder?: string;
  required?: boolean;
  type?: string;
  rows?: number;
}

const TextArea = <TFieldValues extends FieldValues>({
  autoFocus,
  control,
  hideLabel,
  includeLayoutClass,
  label,
  name,
  placeholder,
  required,
  rows = 3,
  type = 'text',
  ...inputAttributes
}: Props<TFieldValues>) => {
  const {
    field: { onBlur, onChange, ref, value },
    fieldState: { error, invalid },
  } = useController<TFieldValues>({ control, name });

  const [id] = useState(generateHtmlElementId);
  const [errorMessageId] = useState(generateHtmlElementId);
  const [hasFocus, setHasFocus] = useState(false);

  const handleBlur = () => {
    setHasFocus(false);
    onBlur();
  };

  const handleFocus = () => {
    setHasFocus(true);
  };

  return (
    <InputWrapper
      aria-errormessage={invalid ? errorMessageId : undefined}
      aria-invalid={invalid ? true : undefined}
      id={id}
      includeLayoutClass={includeLayoutClass}
      invalid={Boolean(error) && !hasFocus}
      hideLabel={hideLabel}
      label={label}
      name={name}
      required={required}
      type={type}
    >
      <textarea
        autoFocus={autoFocus}
        className="form-control"
        id={id}
        name={name}
        onBlur={handleBlur}
        onFocus={handleFocus}
        onChange={onChange}
        placeholder={placeholder}
        ref={ref}
        rows={rows}
        value={isUndefined(value) ? '' : value}
        {...inputAttributes}
      />
      <ErrorMessage error={error} id={errorMessageId} />
    </InputWrapper>
  );
};

export default TextArea;
