import React from 'react';
import { Button, Modal } from 'shared/bootstrap';
import * as I18n from 'shared/utils/I18n';
import useChangeEmailRequest from './useChangeEmailRequest';
import ServerError from 'shared/components/ServerError';
import LoadingAnimation from 'namespaces/shared/components/LoadingAnimation';
import TextInput from 'hookForm/TextInput';
interface Props {
  onHide: () => void;
  show: boolean;
}

const ns = 'app.profilePage.changeEmail.modal';

const ChangeEmailModal = ({ onHide, show }: Props) => {
  const { control, handleSubmit, isSubmitting, newEmail, status, resetForm } =
    useChangeEmailRequest();

  const handleClose = () => {
    onHide();
    setTimeout(() => {
      resetForm();
    }, 300);
  };

  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>{I18n.nt(ns, 'requestEmailChange')}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit} className="change-email">
        <Modal.Body>
          {status === 'error' && <ServerError />}
          {status === 'requesting' && <LoadingAnimation />}
          {status === 'requested' ? (
            <div>{I18n.nt(ns, 'successMessage', { value: newEmail })}</div>
          ) : (
            <>
              <TextInput
                data-autofocus
                control={control}
                label={I18n.nt(ns, 'newEmail')}
                name="newEmail"
                type="email"
              />
              <TextInput
                control={control}
                label={I18n.nt(ns, 'newEmailConfirmation')}
                name="newEmailConfirmation"
                type="email"
              />
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          {status === 'requested' ? (
            <Button className="btn-primary" onClick={handleClose}>
              {I18n.nt(ns, 'goBack')}
            </Button>
          ) : (
            <>
              <Button onClick={onHide}>{I18n.nt(ns, 'cancel')}</Button>
              <Button
                disabled={isSubmitting}
                type="submit"
                className="btn-primary"
              >
                {I18n.nt(ns, 'submit')}
              </Button>
            </>
          )}
        </Modal.Footer>
      </form>
    </Modal>
  );
};

export default ChangeEmailModal;
