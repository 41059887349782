import React from 'react';
import Button from 'shared/components/tailwind/Button';
import * as I18n from 'shared/utils/I18n';

const ns = 'app.profilePage.changePassword';

const ChangePasswordRequest = ({ email, requested, requestChangePassword }) => {
  return (
    <div className="tw-flex tw-h-full tw-flex-col tw-rounded-lg tw-bg-floryn-tint-gray tw-p-6">
      <p className="tw-mb-3 tw-font-heading tw-font-semibold">
        {I18n.nt(ns, 'title')}
      </p>
      {!requested ? (
        <>
          <p className="tw-mb-6 tw-flex-1">{I18n.nt(ns, 'text')}</p>
          <Button
            className="btn-primary tw-w-fit"
            onClick={requestChangePassword}
          >
            {I18n.nt(ns, 'button')}
          </Button>
        </>
      ) : (
        <p className="tw-mb-6 tw-flex-1">
          {I18n.nt(ns, 'confirmationText', { email })}
        </p>
      )}
    </div>
  );
};

export default ChangePasswordRequest;
