import { useEffect, useState } from 'react';

import { trackEvent } from 'shared/utils/tracker';
import useCurrentSeller from 'shared/hooks/useCurrentSeller';
import { aisRequestRemote } from 'shared/utils/aisClient';
import {
  requireAnyRefresh,
  transformAccountsIntoBanks,
} from 'shared/components/Consent/RefreshModal/helpers';
import useReviewStatusForProduct from './useReviewStatusForProduct';
import { Consents, Remote } from 'types';

type RemoteConsents = Remote<Consents>;

type CurrentStep = 'welcome' | 'review';

const useReactivationModal = (
  onHideModal: () => void,
  onShowModal: () => void
) => {
  const seller = useCurrentSeller();
  const reviewStatusForProduct = useReviewStatusForProduct();

  const [currentStep, setCurrentStep] = useState<CurrentStep>('welcome');
  const [visible, setVisible] = useState(true);
  const [remoteConsents, setRemoteConsents] = useState<RemoteConsents>({
    type: 'not_asked',
  });

  const loadConsents = async () => {
    const remoteData = await aisRequestRemote<Consents>(
      '/v2/user/consents',
      seller.id
    );
    setRemoteConsents(remoteData);
  };

  useEffect(() => {
    onShowModal();
    trackEvent('Reactivation modal: show welcome step');

    if (seller.aisConnected) {
      loadConsents();
    }
  }, []);

  const hideModal = () => {
    setVisible(false);
    onHideModal();
    trackEvent('Reactivation modal: closed');
  };

  const banks =
    remoteConsents.type === 'success'
      ? transformAccountsIntoBanks(remoteConsents.response.consents)
      : [];

  const requirePsd2Refresh =
    Boolean(seller.aisConnected) &&
    remoteConsents.type === 'success' &&
    requireAnyRefresh(banks);

  const requireBankStatements =
    reviewStatusForProduct === 'open' || reviewStatusForProduct === 'expired';

  const onClickWithdrawStep = () => {
    trackEvent('Reactivation modal: withdraw money');

    if (requirePsd2Refresh || requireBankStatements) {
      trackEvent('Reactivation modal: show review step');
      return setCurrentStep('review');
    }

    return hideModal();
  };

  const isLoaded = seller.aisConnected
    ? remoteConsents.type === 'success'
    : true;

  return {
    currentStep,
    hideModal,
    isLoaded,
    requireBankStatements,
    requirePsd2Refresh,
    onClickWithdrawStep,
    visible,
  };
};

export default useReactivationModal;
