import { useCallback, useState } from 'react';
import { useForm } from 'react-hook-form';

import resolver from 'hookForm/resolver';
import { useCurrentSeller } from 'namespaces/shared/hooks';
import { validatePresence } from 'shared/utils/validation';
import client from 'shared/utils/client';
import { useSelector } from 'react-redux';
import { ReduxState } from 'types';

interface FormValues {
  explanation?: string;
  newStreet: string;
  newHouseNumber: string;
  newCity: string;
  newPostalCode: string;
  category: string;
  newValue: string;
}

type Status = '' | 'error' | 'requesting' | 'requested';

const useChangeAddressForm = () => {
  const [status, setStatus] = useState<Status>('');

  const validate = ({
    newStreet,
    newCity,
    newPostalCode,
    newHouseNumber,
  }: FormValues) => ({
    newStreet: validatePresence(newStreet),
    newCity: validatePresence(newCity),
    newPostalCode: validatePresence(newPostalCode),
    newHouseNumber: validatePresence(newHouseNumber),
  });

  const seller = useCurrentSeller();

  const company = useSelector(
    (state: ReduxState) => state.entities.companies[seller.company]
  );

  const {
    control,
    handleSubmit,
    formState: { isSubmitting },
    reset,
  } = useForm<FormValues>({
    resolver: resolver(validate),
  });

  const requestAddressChange = async (data: FormValues) => {
    setStatus('requesting');

    const response = await client(
      'POST',
      '/api/request_changes/address',
      {
        category: 'changeAddress',
        newStreet: data.newStreet,
        newHouseNumber: data.newHouseNumber,
        newCity: data.newCity,
        newPostalCode: data.newPostalCode,
        explanation: data.explanation,
      },
      {
        raiseError: false,
      }
    );

    if (response.error) {
      setStatus('error');
    } else {
      setStatus('requested');
      reset();
    }
  };

  const resetForm = useCallback(() => {
    setStatus('');
    reset();
  }, []);

  return {
    company,
    control,
    handleSubmit: handleSubmit(requestAddressChange),
    isSubmitting,
    resetForm,
    seller,
    status,
  };
};

export default useChangeAddressForm;
