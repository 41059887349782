import React from 'react';
import { concat, groupBy } from 'lodash';

import { OnboardingActionStatus } from 'types';

interface ActionStatus {
  status: OnboardingActionStatus;
}

interface ActionProps {
  props: ActionStatus;
}

// The children are Action elements or a boolean when a condition is used.
interface Props {
  children: (boolean | React.ReactElement<ActionProps>)[];
}

const Actions = ({ children }: Props) => {
  // Create an array of the children and filter out the booleans.
  const array = React.Children.toArray(children).filter(React.isValidElement);

  const grouped = groupBy<React.ReactElement>(
    array,
    (child: ActionProps) => child.props.status
  );
  const ordered = concat(grouped.Todo, grouped.Pending, grouped.Done);

  return <div className="SellerActions">{ordered}</div>;
};

export default Actions;
